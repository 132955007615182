<template>
  <div class="color-le bgc-e8" style="height: auto">
    <div class="pay_banner">
      <img
        style="width: 100%; margin-top: 60px; dispaly: block"
        src="../../assets/img/pay/pay_banner.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="pay-icon w-100 bgc-pay-icon pt-5">
      <div class="container">
        <h3 style="margin-bottom: 16px; color: #000">充值选择</h3>
        <div class="row justify-content-lg-between justify-content-sm-center">
          <div
            class="col-lg col-md-5 col-xs-4 mt-lg-0 mt-sm-4 ml-sm-1 vip-pay-item bgc_w "
          >
            <div class="text-right invisible">
              <div class="color-f20 fz-20 vip-text">VIP</div>
            </div>
            <div class="text-center">
              <img src="../../assets/img/personal/free.png" alt="" />
            </div>
            <div class="text-center fz-20 color-4d mt-1">VIP功能受限</div>
            <div class="text-center fz-26 color-4d" style="margin-top: 10px">
              免费试用
            </div>
            <div class="text-center mt-5">
              <span
                @click="$router.go(-1)"
                class="d-inline-block w-75 btn-4c br_4"
                style="color:#fff;padding: 10px; 0;font-size:18px;font-weight:bold;"
                >我再看看</span
              >
            </div>
          </div>
          <div
            class="col-lg col-md-5 col-xs-4 vip-pay-item bgc_w ml-lg-3 ml-md-0 mt-sm-4 mt-lg-0 "
            v-for="(val, t) in payArray"
            :key="t"
            :class="t % 2 == 0 ? 'ml-md-2' : 'ml-md-0'"
          >
            <div class="text-center">
              <img :src="val.img" style="height: 90px; margin-top: 40px" alt="" />
            </div>
            <div class="text-center fz-20 color-4d">充值{{ val.point }} 点</div>
            <div class="text-center">
              <span class="color-4d"></span
              ><span class="color-f49 fz-28" style="width: 50px">￥{{ val.price }}</span
              ><span class="color-f49 fz-26">元</span>
            </div>
            <div class="text-center mt-5">
              <router-link
                :to="{
                  name: val.rt,
                  params: { price: val.price, point: val.point },
                }"
                class="d-inline-block w-75"
                style="background:#E8C173; border:none;color:#fff;padding: 10px; 0;font-size:18px;font-weight:bold;"
                >购买</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 50px" class="container">
        <div>
          <h3 class="color-4d fz-24">使用权限对比</h3>
        </div>
        <!-- <div
          class="row justify-content-between mt-5"
          style="height: 500px; justify-content: center !important"
        >
          <div class="col-md-4 col-sm-10" style="background: #fff; padding: 0">
            <div class="color-0 text-center" style="margin: 1.5rem 0">
              <span class="d-inline-block fz-24">
                <em class="fz-24" style="font-weight: 700; font-size: 18px">普通用户</em>
              </span>
            </div>
            <div
              class="w-100"
              style="
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              无需充值，注册即可使用
            </div>
            <div
              class="w-100"
              style="
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              只能查询一般企业报表
            </div>
            <div
              class="w-100"
              style="
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              只能浏览财务基本概览
            </div>
            <div
              class="w-100"
              style="
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              不能下载完整的财务报表
            </div>
            <div style="text-align: center; margin-top: 48px">
              <router-link
                :to="{
                  name: payArray[0].rt,
                  params: { price: payArray[0].price, point: payArray[0].point },
                }"
                class="d-inline-block w-75"
                style="background:#E8C173; border:none;color:#fff;padding: 10px; 0;font-size:18px;font-weight:bold;width:168px;text-algin:center;width: 168px!important;text-align: center;"
                >购买</router-link
              >
            </div>
          </div>
          <div
            class="row col-md-2 text-center col-sm-12"
            style="margin-top: -15px; justify-content: center; align-items: center"
          >
            <span class="color-4ad fz-36">V</span><span class="color-f20 fz-36">S</span>
          </div>
          <div class="col-md-4 col-sm-10" style="background: #fff; padding: 0">
            <div class="color-0 text-center" style="margin: 1.5rem 0">
              <span class="d-inline-block fz-24">
                <em
                  class="fz-24"
                  style="font-weight: 700; font-size: 18px; color: #e8c173"
                  >vip用户</em
                >
              </span>
            </div>
            <div
              class="w-100"
              style="
                position: relative;
                height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-top: 10px;
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              <div
                class="ivu-icon ivu-icon-md-checkmark"
                style="
                  position: absolute;
                  left: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #e8c173;
                "
              ></div>
              <div>充值点数后，立即成为vip用户。当可用点数为0时降级为普通用户</div>
            </div>
            <div
              class="w-100"
              style="
                position: relative;
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              <div
                class="ivu-icon ivu-icon-md-checkmark"
                style="
                  position: absolute;
                  left: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #e8c173;
                "
              ></div>
              可以查询所有类型企业和事业单位财务报表
            </div>
            <div
              class="w-100"
              style="
                position: relative;
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              <div
                class="ivu-icon ivu-icon-md-checkmark"
                style="
                  position: absolute;
                  left: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #e8c173;
                "
              ></div>
              可以获得财务粉饰和财务风险的详细分析
            </div>
            <div
              class="w-100"
              style="
                position: relative;
                height: 62px;
                line-height: 62px;
                border-top: 1px solid rgba(112, 112, 112, 0.5);
                padding-left: 50px;
                color: rgba(42, 39, 39, 1);
              "
            >
              <div
                class="ivu-icon ivu-icon-md-checkmark"
                style="
                  position: absolute;
                  left: 15px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #e8c173;
                "
              ></div>
              可以下载完整的财务报表
            </div>
            <div style="text-align: center; margin-top: 48px">
              <router-link
                :to="{
                  name: payArray[0].rt,
                  params: { price: payArray[0].price, point: payArray[0].point },
                }"
                class="d-inline-block w-75"
                style="background:#E8C173; border:none;color:#fff;padding: 10px; 0;font-size:18px;font-weight:bold;width:168px;text-algin:center;width: 168px!important;text-align: center;"
                >购买</router-link
              >
            </div>
          </div>
        </div> -->
        <img
          src="~@/assets/img/person/b.png"
          style="width: 100%; margin-top: 20px"
          alt=""
          srcset=""
        />
      </div>
    </div>
    <!--VS-->
    \
  </div>
</template>

<script>
export default {
  data() {
    return {
      a: 20,
      payArray: [
        {
          point: 20,
          price: 20,
          rt: "FirstClass",
          img: require("../../assets/img/personal/50.png"),
        },
        {
          point: 50,
          price: 50,
          rt: "SecondClass",
          img: require("../../assets/img/personal/50.png"),
        },
        {
          point: 200,
          price: 195,
          rt: "ThirdClass",
          img: require("../../assets/img/personal/195.png"),
        },
        {
          point: 1000,
          price: 950,
          rt: "FourthClass",
          img: require("../../assets/img/personal/950.png"),
        },
      ],
    };
  },
};
</script>

<style>
/* .page_head {
  background: url(../../assets/img/search/bg_search.png) repeat center center;
  background-size: cover;
} */
.bgc-pay-icon {
  padding-bottom: 50px;
}

.vip-pay-item {
  height: 316px;
}
.vip-text {
  transform: rotate(45deg) translateY(2px) translateX(10px);
}
.btn-4c {
  color: #4983fc !important;
  cursor: pointer;
  border: 1px solid #4983fc;
}
.btn-4c:hover {
  color: #fff !important;
  background-color: #4983fc;
}
</style>
